import * as React from "react"
import { Link } from "gatsby"

//import components
import Navbar from "../components/Navbar/Navbar";

const NotFoundPage = () => {
  return (
    <main>
      <Navbar />
      <h1><Link to="/">Go home</Link></h1>
    </main>
  )
}

export default NotFoundPage
